import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Body } from '../primitive/typography';
import { IconTime } from '../primitive/icons';
import ButtonRound from '../action/ButtonRound';
import { cover, hideText } from 'polished';
import Tag from '../primitive/Tag';

interface Props {
    videoTitle?: ReactNode;
    videoSubtitle?: ReactNode;
    tag?: string;
    startTime?: string;
    link: ReactNode;
    icon: ReactNode;
}

const BlockShowList: FC<Props> = ({
    videoTitle,
    videoSubtitle,
    tag,
    startTime,
    link,
    icon,
    ...props
}) => (
    <Block as="li" {...props}>
        <Content>
            <Body as="h4" variant="small">
                {videoTitle && <><strong>{videoTitle}</strong><br /></>}
                {videoSubtitle}
            </Body>
            {tag && <Tag>{tag}</Tag>}
            {startTime && (
                <Meta>
                    <IconTime />
                    {startTime}
                </Meta>
            )}
            <LinkContainer>{link}</LinkContainer>
        </Content>
        <ButtonRound icon={icon} />
    </Block>
);

const Block = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0;
    position: relative;

    &:first-child {
        border-top: none;
    }
`;

const Content = styled.div`
    margin-right: 1.6rem;

    ${Tag} {
        margin: 0.8rem 0;
    }
`;

const Meta = styled(Body).attrs({
    variant: "tiny",
})`
    margin-top: 0.4rem;
    color: rgba(255, 255, 255, 0.6);

    & > svg {
        width: 1em;
        vertical-align: -15%;
        margin-right: 0.5em;
    }
`;

const LinkContainer = styled.div`
    & > * {
        ${cover()}
        ${hideText()}
    }
`;

export default BlockShowList;
