import React, { FC } from "react";
import styled from "styled-components";
import Frame from "../layout/Frame";
import { Action } from "../primitive/typography";
import { transparentize } from "polished";
import cssGlitch from "../../../utils/cssGlitch";

interface Props extends React.HTMLAttributes<HTMLElement> {
    label: string;
    className?: string;
    on?: boolean;
}

const ButtonToggle: FC<Props> = React.memo(({ children, label, on, ...props }) => (
    <StyledA color="#56CB28" toggleOn={on} {...props}>
        <Frame variant="line" tl tr br bl background>
            <Label title={label} as="span">
                {label}
            </Label>
        </Frame>
    </StyledA>
));

const StyledA = styled.a<{ color?: string; toggleOn?: boolean }>`
    display: inline-block;
    cursor: pointer;

    & > * {
        overflow: hidden;

        ${({ toggleOn }) =>
            toggleOn &&
            `& > svg {
            color: transparent;
        }`}
    }

    & > *:before {
        transition: 0.05s;
        ${({ toggleOn, color }) =>
            toggleOn &&
            `
                background-image: url('${require("../../../images/texture-black.png")}');
                background-color: ${color || "rgba(255,255,255,0.3)"}
            `}
    }

    &:hover > *:before {
        ${({ color }) => `
                background-image: url('${require("../../../images/texture-black.png")}');
                background-color: ${
                    color ? transparentize(0.2, color) : "rgba(255,255,255,0.3)"
                }
            `};
    }
`;

const Label = styled(Action)`
    display: block;
    margin: 0.8rem 1.2rem;
    white-space: nowrap;
    
    ${cssGlitch}
`;

export default ButtonToggle;
