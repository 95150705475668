
import { Craft_Onlinefestival } from '../graphql';

// gets start + end date from OnlineFestival entries
const getFestivalPeriod = (entries: Craft_Onlinefestival[]) => {
    const startDate = Math.min(...entries.map((entry) => entry.playDate));
    const endDate = Math.max(
        ...entries
            .map((entry) => {
                const festival = entry;
                return [festival.playDate, festival.endDate] as const;
            })
            .reduce((a, b) => [...a, ...b], [])
    );
    return { startDate, endDate };
};
export default getFestivalPeriod;
