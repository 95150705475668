import React from 'react';
import { Craft_IconEnum } from "../graphql";
import { IconAudio, IconPlay, IconArtist, IconGeneric } from '../components/ds/primitive/icons';

export default (linkIcon?: Craft_IconEnum | null) => {
    switch (linkIcon) {
        case 'sound':
            return <IconAudio />
        case 'video':
            return <IconPlay />
        case 'artist':
            return <IconArtist />
        default:
            return <IconGeneric />;
            
    }
}