import React, { FC, useState, useEffect, useContext, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { graphql, Link } from 'gatsby';

import LayoutMain from '../components/ds/layout/LayoutMain';
import Radar, { RadarInterface, latToY, lngToX } from '../components/ds/layout/Radar';
import { TitleBlock, InnerBlock } from '../components/ds/block/BlockWithTitle';
import Content from '../components/ds/content/Content';
import { Body, H2 } from '../components/ds/primitive/typography';
import BlockShowModal from '../components/ds/block/BlockShowModal';
import Blip from '../components/ds/action/Blip';
import MenuSidebar from '../components/MenuSidebar';
import cssCalcBetweenViewport from '../utils/cssCalcBetweenViewport';
import objValMatch from '../utils/objValMatch';
import { OnlinefestivalQuery, Craft_EventsEvent } from '../graphql';
import useParsedOnlinefestivalQuery from '../services/useParsedOnlinefestivalQuery';
import { useDayNavigation } from '../services/useDayNavigation';
import BlockClock from '../components/ds/block/BlockClock';
import iconForEventIcon from '../utils/iconForEventIcon';
import iconForLinkIcon from '../utils/iconForLinkIcon';
import { IconPlay, IconPlayLarge, IconPlus } from '../components/ds/primitive/icons';
import filterCurrentShownEvents from '../services/filterCurrentShownEvents';
import useToggleFilters from '../services/useToggleFilters';
import { useIsMobile } from '../utils/useIsMobile';
import { useIsTablet } from '../utils/useIsTablet';
import Button from '../components/ds/action/Button';
import BlockModal from '../components/ds/block/BlockModal';
import eventNowLive from '../services/eventNowLive';
import ReactPlayer from 'react-player';
import dateFromSeconds from '../utils/dateFromSeconds';
import { DateTime } from 'luxon';
import Helmet from 'react-helmet';
import isBrowser from '../utils/isBrowser';
import AuthContext, { Auth } from '../contexts/AuthContext';
import AuthBlockModal from '../components/AuthBlockModal';
import userHasAccess from '../services/userHasAccess';
import TicketOptions from '../components/TicketOptions';
import userEventTicketOption from '../services/userEventTicketOption';

export const query = graphql`
    query Onlinefestival {
        craft {
            entries(type: Onlinefestival) {
                ... on Craft_Onlinefestival {
                    title
                    playDate
                    endDate
                    introMedia
                    events {
                        ... on Craft_EventsHost {
                            __typename
                            id
                            blockTitle
                            media
                            mediaType
                            image {
                                url: url(transform: onlinefestivalThumbnail)
                                urlRetina: url(transform: onlinefestivalThumbnailRetina)
                            }
                        }
                        ... on Craft_EventsQuote {
                            __typename
                            blockTitle
                            description
                        }
                        ... on Craft_EventsEvent {
                            __typename
                            id
                            blockTitle
                            artists
                            size
                            icon
                            infoLabel
                            hidden
                            location {
                                lat
                                lng
                            }
                            activityTypes_FieldData {
                                selected {
                                    label
                                    value
                                }
                            }
                            image {
                                background: url(transform: header)
                                url: url(transform: onlinefestivalThumbnail)
                                urlRetina: url(transform: onlinefestivalThumbnailRetina)
                            }
                            media
                            mediaType
                            visibilityStart
                            eventStart
                            eventEnd
                            programmingTime {
                                __typename
                                ... on Craft_ProgrammingTime {
                                    slug
                                    activeTicketsId
                                    isOnDemand
                                    validFrom
                                    validUntil
                                    programming {
                                        __typename
                                        ... on Craft_Programming {
                                            slug
                                            ticketType {
                                                slug
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on Craft_EventsLink {
                            __typename
                            id
                            linkIcon
                            blockTitle
                            hidden
                            location {
                                lat
                                lng
                            }
                            media
                        }
                    }
                }
            }
        }
    }
`;

const Home: FC<{ data: OnlinefestivalQuery }> = ({ data, ...props }) => {
    const [intro, setIntro] = useState<boolean>(true);
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [quoteVisible, setQuoteVisible] = useState(false);
    const [hostVisible, setHostVisible] = useState(false);

    const { auth } = useContext(AuthContext);

    useEffect(() => {
        setQuoteVisible(!isMobile);
        setHostVisible(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        if (isTablet) {
            setQuoteVisible(false);
        }
    }, [isTablet]);

    const [, setLastUpdate] = useState<Date>(new Date());

    const { parsedData, dataLoading } = useParsedOnlinefestivalQuery(data);

    /**
     * Current date
     */
    const {
        data: dayData,
        selectedDate,
        dayNumber,
        dayNotToday,
        firstDay,
        lastDay,
        dayLabel,
        onPrevDay,
        onNextDay,
    } = useDayNavigation(parsedData);

    /**
     * Open blip state
     */

    const [openBlipIndex, setOpenBlipIndex] = useState<number | null>(null);

    /**
     *  Filters & live toggle
     */

    const { filters, filtersEnabled, onToggleFilter } = useToggleFilters(dayData?.events);

    // local live filter states
    const [liveOnly, setLiveOnly] = useState<boolean>(false);

    useEffect(() => {
        // refresh on interval
        const interval = setInterval(() => {
            setLastUpdate(new Date());
        }, 30000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const tagForTicketOptionStatus = useCallback((event: Craft_EventsEvent, auth: Auth) => {
        switch (userEventTicketOption(event, auth)) {
            case 'BUY':
                return 'Koop ticket';
            case 'BUY_WRISTBAND':
                return 'Koop toegangsbewijs';
            case 'WATCH_ON_DEMAND':
                return 'On Demand';
        }
        return undefined;
    }, []);

    return dayData ? (
        <LayoutMain>
            <Helmet>
                <title>Het Imaginaire Eiland / Imagine Your Island | Oerol</title>
                <meta property="og:title" content="Het Imaginaire Eiland / Imagine Your Island | Oerol" />
                <meta
                    property="og:description"
                    content="Ondanks het coronavirus kruipt het bloed waar het niet gaan kan."
                />
            </Helmet>
            <main>
                {!isMobile && <PositionedRadarInterface expanded={sidebarOpen} />}
                <PositionedBlockClock
                    date={dayNotToday ? selectedDate : undefined}
                    time={dayLabel}
                    onNextDay={() => {
                        onNextDay();
                    }}
                    onPrevDay={() => {
                        onPrevDay();
                    }}
                    disablePrev={firstDay}
                    disableNext={lastDay}
                    // forcibly set to false for the time being, at request of oerol
                    showNavigation={false}
                />
                {!isMobile && <PositionedAuthBlockModal open={!auth.isLoggedIn} />}
                {dayData.quote && (
                    <>
                        <BabsBlockModal
                            open={quoteVisible}
                            background="pattern"
                            canClose
                            onClickClose={() => setQuoteVisible(false)}
                        >
                            <TitleBlock>
                                <H2>{dayData.quote.blockTitle}</H2>
                            </TitleBlock>
                            <InnerBlock>
                                <Content>
                                    <Body>{dayData.quote.description}</Body>
                                </Content>
                            </InnerBlock>
                        </BabsBlockModal>
                        {!isMobile && (
                            <BabsToggleButton
                                label={dayData.quote.blockTitle || ''}
                                icon={<IconPlus />}
                                onClick={() => {
                                    setQuoteVisible(!quoteVisible);
                                }}
                                visible={!quoteVisible}
                            />
                        )}
                    </>
                )}
                {!isMobile && (
                    <PositionedRadarIsland
                        expanded={sidebarOpen}
                        blips={[
                            ...filterCurrentShownEvents(dayData, filtersEnabled, liveOnly, dayNotToday)?.map(
                                (event, i) => (
                                    <Blip
                                        key={i}
                                        x={lngToX(event.location?.lng)}
                                        y={latToY(event.location?.lat)}
                                        onClick={() => {
                                            if (openBlipIndex !== i) {
                                                setOpenBlipIndex(i);
                                            }
                                        }}
                                        onMouseEnter={() => {
                                            if (openBlipIndex !== i) {
                                                setOpenBlipIndex(i);
                                            }
                                        }}
                                        style={{
                                            zIndex: openBlipIndex === i ? 1 : undefined,
                                        }}
                                        selected={openBlipIndex === i}
                                        variant={eventNowLive(event, dayData.date) && !dayNotToday ? 'live' : 'default'}
                                        hidden={!!event.hidden}
                                        inactive={!userHasAccess(auth, event)}
                                    >
                                        <BlipBlockShowModal
                                            open={openBlipIndex === i}
                                            link={<TicketOptions event={event} />}
                                            videoTitle={event.artists}
                                            videoSubtitle={event.blockTitle}
                                            startTime={
                                                event.eventStart &&
                                                dateFromSeconds(event.eventStart).toLocaleString(DateTime.TIME_SIMPLE)
                                            }
                                            tag={[event.infoLabel, tagForTicketOptionStatus(event, auth)]}
                                            imageProps={{
                                                ratio: 240 / 135,
                                                src: event.image?.[0]?.url || '',
                                                srcSet: `${event.image?.[0]?.url} 1x`,
                                            }}
                                            onClickClose={(e) => {
                                                e.stopPropagation();
                                                setOpenBlipIndex(null);
                                            }}
                                            icon={iconForEventIcon(event.icon)}
                                        />
                                    </Blip>
                                )
                            ),
                            ...dayData.links.map((link, i) => (
                                <Blip
                                    key={1000 + i}
                                    variant="icon"
                                    x={lngToX(link.location?.lng)}
                                    y={latToY(link.location?.lat)}
                                    icon={iconForLinkIcon(link.linkIcon)}
                                    onMouseEnter={() => {
                                        setOpenBlipIndex(1000 + i);
                                    }}
                                    style={{
                                        zIndex: openBlipIndex === 1000 + i ? 1 : undefined,
                                    }}
                                    selected={openBlipIndex === 1000 + i}
                                    link={
                                        link.media ? (
                                            <a href={link.media} target="_blank">
                                                {link.blockTitle}
                                            </a>
                                        ) : undefined
                                    }
                                    hidden={!!link.hidden}
                                    inactive={false}
                                >
                                    {link.blockTitle}
                                </Blip>
                            )),
                        ]}
                    />
                )}
                {!isMobile && !isTablet && dayData.host && dayData.host.media && (
                    <HostsContainer expanded={sidebarOpen}>
                        <HostBlockShowModal
                            expanded={sidebarOpen}
                            open={hostVisible}
                            title={<H2>Captains</H2>}
                            videoTitle={dayData.host.blockTitle}
                            imageProps={{
                                ratio: 492 / 282,
                                src: dayData.host.image?.[0]?.url || '',
                                srcSet: `${dayData.host.image?.[0]?.url} 1x`,
                            }}
                            link={
                                <Link
                                    to={`/show/${dayData.host.id}`}
                                    state={{
                                        prevUrl: isBrowser && window.location.href,
                                    }}
                                >
                                    {dayData.host.blockTitle}
                                </Link>
                            }
                            icon={<IconPlay />}
                            canClose={true}
                            onClickClose={() => {
                                setHostVisible(!hostVisible);
                            }}
                        />
                        {!isMobile && (
                            <HostToggleButton
                                label="Captains"
                                icon={<IconPlus />}
                                onClick={() => {
                                    setHostVisible(!hostVisible);
                                }}
                                visible={!hostVisible}
                            />
                        )}
                    </HostsContainer>
                )}
                {!isMobile && isTablet && (
                    <HostsContainer expanded={sidebarOpen}>
                        {dayData.host && dayData.host.media && (
                            <Button
                                label="Captains"
                                icon={<IconPlayLarge />}
                                link={
                                    <Link
                                        to={`/show/${dayData.host.id}`}
                                        state={{
                                            prevUrl: isBrowser && window.location.href,
                                        }}
                                    >
                                        Captains
                                    </Link>
                                }
                                as="span"
                            />
                        )}
                    </HostsContainer>
                )}
                {/* Mobile Elements */}
                {isMobile && (
                    <MobileToggles>
                        {dayData.quote && (
                            <Button
                                label={dayData.quote.blockTitle || ''}
                                icon={<IconPlus />}
                                onClick={() => {
                                    setQuoteVisible(true);
                                }}
                            />
                        )}
                        {dayData.host && dayData.host.media && (
                            <Button
                                label="Captains"
                                icon={<IconPlayLarge />}
                                link={
                                    <Link
                                        to={`/show/${dayData.host.id}`}
                                        state={{
                                            prevUrl: isBrowser && window.location.href,
                                        }}
                                    >
                                        Captains
                                    </Link>
                                }
                                as="span"
                            />
                        )}
                    </MobileToggles>
                )}
                {!isMobile && dayData.introMedia && (
                    <PlayPauseContainer>
                        <ReactPlayer
                            url={dayData.introMedia}
                            width="1px"
                            height="1px"
                            css={'opacity: 1%'}
                            playing={intro}
                            volume={0.5}
                            controls={false}
                            loop={true}
                            config={{
                                soundcloud: {
                                    options: {
                                        hide_related: true,
                                    },
                                },
                                youtube: {
                                    playerVars: {
                                        rel: 0,
                                    },
                                },
                            }}
                        />
                        <PlayPauseButton
                            label={intro ? 'PLAYING INTRO' : 'INTRO PAUSED'}
                            icon={<IconPlayLarge />}
                            onClick={() => {
                                setIntro((intro) => !intro);
                            }}
                        />
                    </PlayPauseContainer>
                )}
            </main>
            <StyledMenuSidebar
                faded={!(!isMobile || (isMobile && !quoteVisible))}
                programAndActivitiesProps={{
                    disabledToggle: dayNotToday,
                    activities: Object.keys(filters)?.map((filterKey) => ({
                        name: filters[filterKey],
                        slug: filterKey,
                        enabled: filtersEnabled[filterKey],
                        on: objValMatch(filtersEnabled, true) ? false : filtersEnabled[filterKey],
                        onToggle: onToggleFilter,
                    })),
                    live: {
                        enabled: liveOnly,
                        onToggle: (on) => {
                            setLiveOnly(!on);
                        },
                    },
                    events: filterCurrentShownEvents(dayData, filtersEnabled, liveOnly, dayNotToday),
                }}
                sidebarProps={{
                    expanded: sidebarOpen,
                    onCloseClick: () => {
                        setSidebarOpen(!sidebarOpen);
                    },
                }}
            />
        </LayoutMain>
    ) : !dataLoading ? (
        <LayoutMain>
            <main>
                <PositionedBlockClock
                    date={dayNotToday ? selectedDate : undefined}
                    time={dayNotToday ? `Dag ${dayNumber}` : undefined}
                    onNextDay={() => {
                        onNextDay();
                    }}
                    onPrevDay={() => {
                        onPrevDay();
                    }}
                    disablePrev={firstDay}
                    disableNext={lastDay}
                    // forcibly set to false for the time being, at request of oerol
                    showNavigation={false}
                />
                <MessagePanel>
                    <h1>Het Imaginaire Eiland is voorbij</h1>
                    <HeaderUnderline />
                    <p>
                        Van 11 - 20 juni was hier een mooi en divers programma te zien. Met voorstellingen speciaal
                        gemaakt voor online vertoning en concerten opgenomen in het Terschellinger Landschap. Een aantal
                        programma onderdelen zijn nog terug te zien via{' '}
                        <a href="https://www.oerol.nl/nl/oerol-gemist" target="_blank">
                            Oerol Gemist
                        </a>
                        !
                    </p>
                </MessagePanel>
            </main>
        </LayoutMain>
    ) : (
        <>loading...</>
    );
};

const PositionedAuthBlockModal = styled(AuthBlockModal)`
    position: absolute;
    bottom: ${cssCalcBetweenViewport(32, 72)};
    left: ${cssCalcBetweenViewport(24, 64)};
    width: ${cssCalcBetweenViewport(240, 464)};
    z-index: 100;

    &:after {
        display: block;
        content: '';
        background: rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
`;

const PositionedBlockClock = styled(BlockClock)`
    position: absolute;
    top: ${cssCalcBetweenViewport(24, 64)};
    left: ${cssCalcBetweenViewport(24, 64)};
    z-index: 2;

    @media (max-width: 580px) {
        position: static;
    }
`;

const expandedRadar = css`
    transform: translateX(-24px);
    @media (min-width: 1025px) {
        transform: translateX(-160px);
    }
`;

const PositionedRadarInterface = styled(RadarInterface)<{ expanded?: boolean }>`
    transition: 1s transform cubic-bezier(0, 0.55, 0.45, 1);
    ${({ expanded }) => expanded && expandedRadar}
    z-index: -1;

    @media (max-width: 580px) {
        display: none;
    }
`;

const PositionedRadarIsland = styled(Radar)<{ expanded?: boolean }>`
    transition: 1s transform cubic-bezier(0, 0.55, 0.45, 1);
    ${({ expanded }) => expanded && expandedRadar}
    z-index: 1;

    @media (max-width: 580px) {
        display: none;
    }
`;

const BlipBlockShowModal = styled(BlockShowModal)`
    position: absolute;
    bottom: calc(100% + 0.8rem);
    left: calc(50%);
    margin-left: ${cssCalcBetweenViewport(-120, -148)};
`;

const BabsBlockModal = styled(BlockModal)`
    position: absolute;
    top: ${cssCalcBetweenViewport(32, 72)};
    left: ${cssCalcBetweenViewport(192, 304)};
    width: ${cssCalcBetweenViewport(240, 325)};

    @media (max-width: 580px) {
        top: calc(100% + 1.6rem);
        left: 0.8rem;
        bottom: auto;
        width: calc(100vw - 1.6rem);
        z-index: 10;
        margin-left: 0;
    }
`;

const BabsToggleButton = styled(Button)<{ visible: boolean }>`
    position: absolute;
    top: ${cssCalcBetweenViewport(32, 72)};
    left: ${cssCalcBetweenViewport(192, 304)};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
`;

const HostsContainer = styled.div<{ expanded?: boolean }>`
    position: absolute;
    bottom: 6.4rem;
    right: ${cssCalcBetweenViewport(408, 488)};

    @media (min-width: 1025px) {
        transition: 1s;
        transform: ${({ expanded }) => !expanded && `translateX(300px)`};
    }

    @media (max-width: 1024px) {
        right: 13.6rem;
        bottom: 3.2rem;
    }

    @media (max-width: 580px) {
        top: calc(100% + 1.6rem);
        left: 0.8rem;
        bottom: auto;
        width: calc(100vw - 1.6rem);
        z-index: 10;
        margin-left: 0;
    }
`;

const HostBlockShowModal = styled(BlockShowModal)<{ expanded?: boolean }>``;

const HostToggleButton = styled(Button)<{ visible: boolean }>`
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
`;

const MobileToggles = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.6rem;

    & > * + * {
        margin-top: 0.4rem;
    }
`;

const StyledMenuSidebar = styled(MenuSidebar)<{ faded: boolean }>`
    opacity: ${({ faded }) => (faded ? 0 : 1)};
`;

const HeaderUnderline = styled.div`
    width: 3rem;
    height: 0.2rem;
    background-color: #56cb28;
`;

const MessagePanel = styled.div`
    padding-right: 2rem;
    h1 {
        font-weight: normal;
    }
    p {
        margin: 2.5rem 0 3rem;
    }
    @media (min-width: 580px) {
        position: absolute;
        top: calc(50% - 15rem);
        left: ${cssCalcBetweenViewport(24, 64)};
        max-width: 50rem;
    }
    @media (max-width: 580px) {
        position: absolute;
        top: calc(100% + 1rem);
        left: 1rem;
    }
`;

const PlayPauseContainer = styled.div`
    z-index: 99;
    position: absolute;
    left: ${cssCalcBetweenViewport(24, 64)};
    bottom: ${cssCalcBetweenViewport(24, 64)};
`;

const PlayPauseButton = styled(Button)``;

export default Home;
