import styled from "styled-components";
import cssCalcBetweenViewport from "../../../utils/cssCalcBetweenViewport";

export default styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    & > main {
        height: 100%;
    }

    & > aside {
        --padding: 1.6rem 1.6rem 1.6rem 0.8rem;
        --width: ${cssCalcBetweenViewport(296, 420)};
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        width: var(--width);
    }

    @media (max-width: 580px) {
        width: auto;
        height: auto;
        overflow: initial;

        & > main {
            position: relative;
            height: auto;
            padding: 8px;
            display: flex;
            align-items: flex-end;
            z-index: 2;
        }

        & > aside {
            position: static;
            width: auto;
        }
    }
`;
