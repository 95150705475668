import { Craft_EventsEvent } from "../graphql";

export default (events: Craft_EventsEvent[]) => {
    const filters: Record<string, string> = {};
    events.map((e) =>
        e.activityTypes_FieldData?.selected?.forEach((at) => {
            if (typeof at?.value === 'string' && typeof at?.label === 'string') {
                filters[at.value] = at.label;
            }
        })
    );

    return filters;
};
