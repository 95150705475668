import React, { FC, useState, useEffect } from 'react';
import { BlipProps } from './Blip';
import { useCallbackRef } from 'use-callback-ref';
import styled, { css } from 'styled-components';
import cssCalcBetweenViewport from '../../../utils/cssCalcBetweenViewport';
import { cover, hideText } from 'polished';
import gsap from 'gsap';

const BlipLive: FC<BlipProps> = React.memo(
    ({ selected = false, x, y, children, link, hidden = false, inactive = false, ...props }) => {
        const [, forceUpdate] = useState();
        const [timelineLive, setTimelineLive] = useState<gsap.core.Timeline>();
        const [timelineOn, setTimelineOn] = useState<gsap.core.Timeline>();
        const [timelineOff, setTimelineOff] = useState<gsap.core.Timeline>();
        const outer = useCallbackRef<SVGSVGElement>(null, () => forceUpdate(null));
        const inner = useCallbackRef<SVGSVGElement>(null, () => forceUpdate(null));
        const fill = useCallbackRef<SVGSVGElement>(null, () => forceUpdate(null));
        const dot = useCallbackRef<SVGSVGElement>(null, () => forceUpdate(null));

        useEffect(() => {
            if (outer.current && inner.current && fill.current && dot.current) {
                if (!timelineLive) {
                    // live timeline
                    const tlLive = gsap.timeline({ repeat: -1 }).pause();
                    tlLive.fromTo(
                        inner.current,
                        { scale: 1, opacity: 1 },
                        { scale: 5, opacity: 0, duration: 1.5, force3D: true },
                        0
                    );
                    tlLive.fromTo(
                        outer.current,
                        { scale: 1, opacity: 1 },
                        { scale: 4, opacity: 0, duration: 2, force3D: true },
                        0
                    );
                    setTimelineLive(tlLive);
                }

                // on timeline
                const tlOn = gsap.timeline().pause();
                tlOn.fromTo(dot.current, { scale: 1 }, { scale: 1.5, duration: 0.5, force3D: true }, 0);
                setTimelineOn(tlOn);

                // off timeline
                const tlOff = gsap.timeline().pause();
                tlOff.fromTo(dot.current, { scale: 1.5 }, { scale: 1, duration: 0.5, force3D: true }, 0);
                setTimelineOff(tlOff);
            }
        }, [outer.current, inner.current, fill.current, selected]);

        useEffect(() => {
            if (timelineOn && timelineOff && timelineLive) {
                if (selected) {
                    // animate in
                    timelineOn.play();
                } else {
                    // animate out
                    timelineOff.play();
                    timelineLive.play();
                }
            }
        }, [selected, timelineOn, timelineOff, timelineLive]);

        return (
            <Container x={x} y={y} hidden={hidden} {...props}>
                <OuterRing viewBox="0 0 28 28" ref={outer}>
                    <circle
                        cx="14"
                        cy="14"
                        r={13}
                        stroke={inactive ? '#999' : '#DC1111'}
                        strokeWidth={0.5}
                        fill={inactive ? '#999' : '#DC1111'}
                        fillOpacity={0.5}
                    />
                </OuterRing>
                <InnerRing viewBox="0 0 28 28" ref={inner}>
                    <circle
                        cx="14"
                        cy="14"
                        r={13}
                        stroke={inactive ? '#999' : '#DC1111'}
                        strokeWidth={0.5}
                        fill={inactive ? '#999' : '#DC1111'}
                        fillOpacity={0.5}
                    />
                </InnerRing>
                <Fill viewBox="0 0 28 28" ref={fill}>
                    <circle cx="14" cy="14" r={13} fill="white" />
                </Fill>
                <Dot viewBox="0 0 28 28" ref={dot}>
                    <circle cx="14" cy="14" r={7} fill={inactive ? '#999' : '#DC1111'} />
                </Dot>
                {link && <LinkContainer>{link}</LinkContainer>}
                {children}
            </Container>
        );
    }
);

const cssPositioning = css<{ x?: number; y?: number }>`
    position: absolute;
    left: ${({ x }) => x}%;
    top: ${({ y }) => y}%;
    transform: translateX(-50%) translateY(-50%);
`;

const Container = styled.div<{ x?: number; y?: number; hidden?: boolean }>`
    position: relative;
    ${({ x, y }) => (typeof x !== 'undefined' && typeof y !== 'undefined' ? cssPositioning : null)}
    width: ${cssCalcBetweenViewport(20, 28)};
    height: ${cssCalcBetweenViewport(20, 28)};
    border-radius: 100%;
    padding: 0rem;
    cursor: pointer;
    opacity: ${({ hidden }) => (hidden ? 0 : 1)};

    & > svg {
        ${cover()}
        will-change: transform;
    }
`;

const OuterRing = styled.svg`
    pointer-events: none;
`;

const InnerRing = styled.svg`
    pointer-events: none;
`;

const Fill = styled.svg`
    pointer-events: none;
`;

const Dot = styled.svg`
    pointer-events: none;
`;

const LinkContainer = styled.span`
    & > a {
        display: block;
        ${cover()}
        ${hideText()}
    }
`;

export default BlipLive;
