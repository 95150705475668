import React, { FC, useContext } from 'react';
import styled from 'styled-components';

import AuthContext from '../contexts/AuthContext';
import { useIsMobile } from '../utils/useIsMobile';
import AuthBlockModal from './AuthBlockModal';
import Button from './ds/action/Button';
import BlockWithTitle from './ds/block/BlockWithTitle';
import Sidebar, { SidebarProps } from './ds/layout/Sidebar';
import { IconExternal } from './ds/primitive/icons';
import { H2 } from './ds/primitive/typography';
import ProgramAndActivities, { ProgramAndActivitiesProps } from './ProgramAndActivities';
import SocialMedia from './SocialMedia';

interface Props {
    programAndActivitiesProps: ProgramAndActivitiesProps;
    sidebarProps: Partial<SidebarProps>;
}

const MenuSidebar: FC<Props> = ({ programAndActivitiesProps, sidebarProps, ...props }) => {
    const { auth } = useContext(AuthContext);
    const isMobile = useIsMobile();

    return (
        <StyledSidebar collapsable {...sidebarProps} {...props}>
            <MenuContent>
                {isMobile && <AuthBlockModal open={!auth.isLoggedIn} />}
                <ProgramAndActivities {...programAndActivitiesProps} />
                <BlockWithTitle title={<H2>Draag bij</H2>}>
                    <ButtonsInRows>
                        {/*<Button*/}
                        {/*    href="https://webshop.oerol.nl/products/fanbandje-oerol-2020"*/}
                        {/*    target="_blank"*/}
                        {/*    label="Fanbandje"*/}
                        {/*    icon={<IconExternal />}*/}
                        {/*/>*/}
                        {/*<br />*/}
                        <Button
                            href="https://www.oerol.nl/nl/vrienden-van-oerol"
                            target="_blank"
                            label="Word Vriend"
                            icon={<IconExternal />}
                        />
                        <br />
                        <Button
                            href="https://www.oerol.nl/nl/steun-oerol"
                            target="_blank"
                            label="Doneer"
                            icon={<IconExternal />}
                        />
                    </ButtonsInRows>
                </BlockWithTitle>
                <BlockWithTitle title={<H2>Ga naar</H2>}>
                    <ButtonsInRows>
                        <Button href="https://webshop.oerol.nl/" target="_blank" label="Shop" icon={<IconExternal />} />
                        <br />
                        <Button
                            href="https://oerol.nl/nl/alfabetische-lijst"
                            target="_blank"
                            label="Website Oerol"
                            icon={<IconExternal />}
                        />
                        <br />
                        <Button
                            href="https://www.oerol.nl/nl/veelgestelde-vragen-oerol-2021/"
                            target="_blank"
                            label="Veelgestelde vragen"
                            icon={<IconExternal />}
                        />
                        <br />
                        <Button
                            href="https://www.oerol.nl/nl/nieuws"
                            target="_blank"
                            label="Nieuws"
                            icon={<IconExternal />}
                        />
                    </ButtonsInRows>
                </BlockWithTitle>
                <BlockWithTitle title={<H2>Volg ons</H2>}>
                    <SocialMedia />
                </BlockWithTitle>
            </MenuContent>
        </StyledSidebar>
    );
};

const StyledSidebar = styled(Sidebar)``;

const ButtonsInRows = styled.div`
    & > * + * {
        margin-top: 1.6rem;
    }
`;

const MenuContent = styled.div`
    margin-left: 5.8rem;

    & > * + * {
        margin-top: 3.2rem;
    }

    @media (max-width: 580px) {
        margin-left: 0;
    }
`;

export default MenuSidebar;
