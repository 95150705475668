import React, { FC, useContext, useState } from 'react';
import BlockWithTitle, { TitleBlock } from './ds/block/BlockWithTitle';
import { Body, H2 } from './ds/primitive/typography';
import ButtonToggle from './ds/action/ButtonToggle';
import styled from 'styled-components';
import ButtonText from './ds/action/ButtonText';
import ShowListView from './ShowListView';
import { Craft_EventsEvent } from '../graphql';
import { useIsMobile } from '../utils/useIsMobile';
import Button from './ds/action/Button';
import { IconAccount, IconFilter, IconList } from './ds/primitive/icons';
import sortActivityTypes from '../services/sortActivityTypes';
// import ButtonTextToggle from './ds/action/ButtonTextToggle';
// import Blip from './ds/action/Blip';
import AuthContext from '../contexts/AuthContext';

export type activity = {
    name: string;
    slug: string;
    on: boolean;
    enabled: boolean;
    onToggle: (slug: string, enabled: boolean) => void;
};

export interface ProgramAndActivitiesProps {
    live: { enabled: boolean; onToggle: (enabled: boolean) => void };
    activities?: activity[];
    events: Craft_EventsEvent[];
    disabledToggle?: boolean;
}

const ProgramAndActivities: FC<ProgramAndActivitiesProps> = React.memo(
    ({ live, activities, events, disabledToggle, ...props }) => {
        const isMobile = useIsMobile();
        const [showList, setShowList] = useState(false);
        const [showFilter, setShowFilter] = useState(false);
        const { auth } = useContext(AuthContext);

        /**
            Todo: fix this so the types are sorted by the order in the cms, not hardcoded like this.
        */
        const sortedActivities =
            activities &&
            sortActivityTypes(activities, [
                'paquet-akwasi',
                'paquet-lionstorm',
                'paquet-massih-hutak',
                'paquet-shishani',
                'podcast-jennifer-muntslag',
                'audio-tips',
                'tips-voor-blinden',
                'language-no-problem',
            ]);

        return (
            <StyledBlockWithTitle title={<H2>Account</H2>} {...props}>
                {auth.isLoggedIn === true && (
                    <AccountContainer>
                        <IconAccount />
                        <Username as="span">{auth.currentUser?.attributes?.email}</Username>
                        <ButtonText label="Uitloggen" onClick={auth.signOut} />
                    </AccountContainer>
                )}
                {auth.isLoggedIn === false && (
                    <AccountContainer>
                        <IconAccount />
                        {process.env.GATSBY_LOGIN_URL && (
                            <ButtonText label="Inloggen" as="a" href={process.env.GATSBY_LOGIN_URL} />
                        )}
                    </AccountContainer>
                )}
                {/* live disabled for now */}
                {/* <StyledTitleBlock>
                    <H2>Programma</H2>
                </StyledTitleBlock>
                <LiveContainer>
                    <LegendBlip inactive={false} variant={disabledToggle ? 'default' : 'live'} />
                    <Body style={{ opacity: disabledToggle ? 0.3 : 1 }}>Alléén live</Body>
                    <ButtonTextToggle
                        label="aan"
                        on={!!live.enabled}
                        onClick={() => {
                            !disabledToggle && live.onToggle(live.enabled);
                        }}
                        isDisabled={disabledToggle}
                    />
                </LiveContainer> */}
                {(!isMobile || (isMobile && showFilter)) && (
                    <>
                        <StyledTitleBlock>
                            <H2>Navigeer</H2>
                        </StyledTitleBlock>
                        <Activities>
                            {sortedActivities?.map((a, i) => (
                                <ButtonToggle
                                    key={i}
                                    label={a.name}
                                    onClick={() => {
                                        a.onToggle(a.slug, a.enabled);
                                    }}
                                    on={!!a.on}
                                />
                            ))}
                        </Activities>
                    </>
                )}
                {isMobile && (
                    <FilterButton
                        label={showFilter ? 'LIJST' : 'FILTEREN'}
                        icon={showFilter ? <IconList /> : <IconFilter />}
                        onClick={() => {
                            setShowFilter(!showFilter);
                        }}
                    />
                )}
                {(!isMobile && showList) || (isMobile && !showFilter) ? (
                    <ShowListView events={events} onClose={() => setShowList(false)} canClose={!isMobile} />
                ) : (
                    <BottomButtonArea>
                        {!isMobile ? (
                            <ButtonText
                                label="Programma A-Z"
                                onClick={() => {
                                    setShowList(true);
                                }}
                            />
                        ) : (
                            <ButtonText
                                label="Terug naar lijst"
                                onClick={() => {
                                    setShowFilter(false);
                                }}
                            />
                        )}
                    </BottomButtonArea>
                )}
            </StyledBlockWithTitle>
        );
    }
);

const StyledTitleBlock = styled(TitleBlock)`
    margin-top: 0 !important;
`;

const StyledBlockWithTitle = styled(BlockWithTitle)`
    @media (max-width: 580px) {
        & > ${TitleBlock}:first-of-type {
            height: 4rem;
            left: 0;
            width: 50%;
        }
    }
`;

const AccountContainer = styled.div`
    display: flex;
    margin-bottom: 2.4rem;

    & > svg {
        margin: 0 0 0 -0.4rem;
        width: 1.6rem;
        height: 1.6rem;
    }

    & > * + * {
        margin-left: 1.2rem;
    }
`;

const Username = styled(Body)`
    overflow: hidden;
    text-overflow: ellipsis;
`;

// const LiveContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     margin-left: -0.8rem;
//     margin-bottom: 2.4rem;

//     > a {
//         margin-left: 2.4rem;
//     }
// `;

const FilterButton = styled(Button)`
    position: absolute;
    top: -4rem;
    left: 50%;
    right: 0;
`;

const Activities = styled.div`
    margin-top: -1.6rem;

    & > * {
        margin-right: 1.2rem;
        margin-top: 1.2rem;
    }
`;

const BottomButtonArea = styled.div`
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
`;

// const LegendBlip = styled(Blip)`
//     transform: scale(0.6);
//     margin-right: 0.8rem;
//     cursor: auto;
// `;

export default ProgramAndActivities;
