import { Craft_EventsEvent } from "../graphql";
import { useEffect, useState } from "react";
import objValMatch from "../utils/objValMatch";
import objValSet from "../utils/objValSet";
import activityTypesForEvents from "./activityTypesForEvents";

const useToggleFilters = (events?: Craft_EventsEvent[]) => {
    const [filters, setFilters] = useState<Record<string, string>>({});
    const [filtersEnabled, setFiltersEnabled] = useState<
        Record<string, boolean>
    >({});

    useEffect(() => {
        if (events) {
            const f = activityTypesForEvents(events);
            const fEnabled: typeof filtersEnabled = {};

            Object.keys(f).map((key) => {
                fEnabled[key] = true;
            });

            setFilters(f);
            setFiltersEnabled(fEnabled);
        }
    }, [events]);

    const onToggleFilter = (slug: string, enabled: boolean) => {
        let f = { ...filtersEnabled };
        if (objValMatch(f, true)) {
            f = objValSet(f, false);
            f[slug] = true;
        } else {
            f[slug] = !enabled;
        }
        if (objValMatch(f, false)) {
            f = objValSet(f, true);
        }
        setFiltersEnabled(f);
    };

    return { filters, filtersEnabled, onToggleFilter };
};

export default useToggleFilters;
