import styled from 'styled-components';
import Frame from '../layout/Frame';
import { bodyMicroCss } from './typography';

export default styled(Frame).attrs({
    tl: true,
    variant: 'dot',
})`
    ${bodyMicroCss}
    padding: 0.2rem 0.8rem;
    letter-spacing: 0;
    display: inline-block;
    background-color: rgba(255,255,255,0.2);

    & + & {
        margin-left: .2rem;
    }
`;
