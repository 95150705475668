import React, { FC, ReactNode } from 'react';
import BlockModal, { BlockModalProps } from './BlockModal';
import styled from 'styled-components';
import Frame from '../layout/Frame';
import Image, { ImageProps } from '../content/Image';
import ButtonRound from '../action/ButtonRound';
import { Body } from '../primitive/typography';
import Tag from '../primitive/Tag';
import cssCalcBetweenViewport from '../../../utils/cssCalcBetweenViewport';
import { hideText } from 'polished';
import { IconTime } from '../primitive/icons';

interface Props extends BlockModalProps {
    title?: ReactNode;
    imageProps: ImageProps;
    videoTitle?: ReactNode;
    videoSubtitle?: ReactNode;
    startTime?: string;
    tag?: string | (string | null | undefined)[];
    link: ReactNode;
    icon: ReactNode;
}

const BlockShowModal: FC<Props> = React.memo(
    ({ open, link, title, imageProps, videoTitle, videoSubtitle, startTime, tag, icon, ...props }) => (
        <StyledBlockModal open={open} background={false} {...props}>
            {title && (
                <TitleFrame variant="dot" tl>
                    {title}
                </TitleFrame>
            )}
            <Image {...imageProps} />
            <Overlay>
                <ButtonBodyContainer>
                    <ButtonRound as="span" icon={icon} />
                    <Body as="h4" variant="tiny">
                        {videoTitle && (
                            <>
                                <strong>{videoTitle}</strong>
                                <br />
                            </>
                        )}
                        {videoSubtitle && videoSubtitle}
                    </Body>
                </ButtonBodyContainer>
                {(startTime || tag) && (
                    <Meta>
                        {startTime && (
                            <Time>
                                <IconTime />
                                {startTime}
                            </Time>
                        )}
                        {tag &&
                            (Array.isArray(tag) ? (
                                tag.filter((t) => t).map((t: string, i) => <Tag key={i}>{t}</Tag>)
                            ) : (
                                <Tag>{tag}</Tag>
                            ))}
                    </Meta>
                )}
            </Overlay>
            <Link>{link}</Link>
        </StyledBlockModal>
    )
);

const StyledBlockModal = styled(BlockModal)`
    width: ${cssCalcBetweenViewport(224, 288)};
    padding: 0.4rem;
`;

const TitleFrame = styled(Frame)`
    position: absolute;
    bottom: 100%;
    right: 100%;
    height: 4.8rem;
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
`;

const Overlay = styled.div`
    position: absolute;
    padding: 4rem 1.6rem 1.6rem;
    bottom: 0.4rem;
    right: 0.4rem;
    left: 0.4rem;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
`;

const Meta = styled.div`
    margin-left: 3.8rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > * {
        margin-right: 0.8rem !important;
        margin-top: 0.4rem !important;
    }
`;

const Time = styled(Body).attrs({
    variant: 'tiny',
})`
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
    display: block;

    & > svg {
        width: 1em;
        vertical-align: -15%;
        margin-right: 0.5em;
    }
`;

const ButtonBodyContainer = styled.div`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: 1.2rem;
    }
`;

const Link = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${hideText()}

    & > * {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export default BlockShowModal;
