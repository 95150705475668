import React from 'react';
import { Craft_LinkIconEnum } from "../graphql";
import { IconHeart, IconTicket, IconEye } from '../components/ds/primitive/icons';

export default (linkIcon?: Craft_LinkIconEnum | null) => {
    switch (linkIcon) {
        case 'eye':
            return <IconEye />
        case 'ticket':
            return <IconTicket />
        case 'heart':
        default:
            return <IconHeart />;
            
    }
}