import React, { FC } from 'react';
import { Craft_EventsEvent } from '../graphql';
import { TitleBlock } from './ds/block/BlockWithTitle';
import styled from 'styled-components';
import { H3 } from './ds/primitive/typography';
import BlockShowList from './ds/block/BlockShowList';
import { IconClose } from './ds/primitive/icons';
import ButtonIcon from './ds/action/ButtonIcon';
import dateFromSeconds from '../utils/dateFromSeconds';
import { DateTime } from 'luxon';
import iconForEventIcon from '../utils/iconForEventIcon';
import TicketOptions from './TicketOptions';

interface Props {
    events: Craft_EventsEvent[];
    onClose: () => void;
    canClose?: boolean;
}

const ShowListView: FC<Props> = ({ events, canClose = true, onClose }) => (
    <>
        <TitleBlock>
            <H3>Programma A-Z</H3>
        </TitleBlock>
        {canClose && (
            <CloseButtonIcon
                icon={<IconClose />}
                onClick={() => {
                    onClose();
                }}
            />
        )}
        <List>
            {events
                ?.sort((a, b) => (a.blockTitle && b.blockTitle ? (a.blockTitle > b.blockTitle ? 1 : -1) : 0))
                .map((event, i) => (
                    <BlockShowList
                        key={i}
                        videoTitle={event.artists}
                        videoSubtitle={event.blockTitle}
                        link={<TicketOptions event={event} />}
                        tag={event.infoLabel || undefined}
                        startTime={
                            event.eventStart && dateFromSeconds(event.eventStart).toLocaleString(DateTime.TIME_SIMPLE)
                        }
                        icon={iconForEventIcon(event.icon)}
                    />
                ))}
        </List>
    </>
);

const List = styled.ul`
    list-style: none;
    margin: -2rem 0 0 0;
    padding: 0;
`;

const CloseButtonIcon = styled(ButtonIcon)`
    position: absolute;
    right: 0;
    margin-top: 2.8rem;
`;

export default ShowListView;
