import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props extends Partial<HTMLHyperlinkElementUtils> {
    icon: ReactNode;
    className?: string;
    as?: 'a' | 'span' | 'div';
}

const ButtonRound: FC<Props> = ({ children, icon, ...props }) => (
    <StyledA {...props}>
        <Icon>{icon}</Icon>
    </StyledA>
);

const StyledA = styled.a`
    display: inline-block;
    width: 2.4rem;
    position: relative;
    background: rgba(7, 7, 7, 0.32);
    border: 1px solid rgba(216, 216, 216, 0.4);
    border-radius: 100%;
    cursor: pointer;
    flex-shrink: 0;
`;

const Icon = styled.div`
    padding-top: 100%;

    & > svg {
        position: absolute;
        left: 50%;
        width: 2.4rem;
        height: 2.4rem;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
`;

export default ButtonRound;
