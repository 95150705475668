import { Craft_EventsEvent, Craft_ProgrammingTime } from '../graphql';
import nowBetween from '../utils/nowBetween';
import { DateTime } from 'luxon';

export default (event: Craft_EventsEvent, day: Date) => {
    const programmingTime: Craft_ProgrammingTime | null | undefined =
        event.programmingTime?.[0] && event.programmingTime[0];
    const isOnDemand = programmingTime?.isOnDemand;

    const start = (isOnDemand ? programmingTime?.validFrom : event.eventStart)
        ? DateTime.fromJSDate(new Date(isOnDemand ? programmingTime?.validFrom * 1000 : event.eventStart * 1000))
        : undefined;
    const end = (isOnDemand ? programmingTime?.validUntil : event.eventEnd)
        ? DateTime.fromJSDate(new Date(isOnDemand ? programmingTime?.validUntil * 1000 : event.eventEnd * 1000))
        : undefined;

    return (
        start &&
        end &&
        nowBetween(start, end, { seconds: true, onlyTime: !isOnDemand }) &&
        event.activityTypes_FieldData?.selected?.find((at) => at?.value === 'live')
    );
};
