import React, { FC, useState, useEffect } from 'react';
import { BlipProps } from './Blip';
import { useCallbackRef } from 'use-callback-ref';
import styled, { css } from 'styled-components';
import cssCalcBetweenViewport from '../../../utils/cssCalcBetweenViewport';
import { cover, hideText } from 'polished';
import gsap from 'gsap';

const BlipDefault: FC<BlipProps> = React.memo(
    ({ selected = false, x, y, children, link, hidden = false, inactive = false, ...props }) => {
        const [, forceUpdate] = useState();
        const [timelineOn, setTimelineOn] = useState<gsap.core.Timeline>();
        const [timelineOff, setTimelineOff] = useState<gsap.core.Timeline>();
        const outer = useCallbackRef<SVGSVGElement>(null, () => forceUpdate(null));
        const inner = useCallbackRef<SVGSVGElement>(null, () => forceUpdate(null));
        const fill = useCallbackRef<SVGSVGElement>(null, () => forceUpdate(null));

        useEffect(() => {
            if (outer.current && inner.current && fill.current) {
                // on timeline
                const tlOn = gsap.timeline().pause();
                tlOn.fromTo(
                    outer.current,
                    { scale: 0, opacity: 1 },
                    { scale: 2, opacity: 0, duration: 1, force3D: true },
                    0
                );
                tlOn.fromTo(
                    inner.current,
                    { scale: 1, opacity: 1 },
                    { scale: 2, opacity: 0, duration: 0.5, force3D: true },
                    0
                );
                tlOn.fromTo(
                    fill.current,
                    { scale: 0, opacity: 0 },
                    { scale: 1, opacity: 1, duration: 0.5, force3D: true },
                    0
                );

                // off timeline
                const tlOff = gsap.timeline().pause();
                tlOff.fromTo(
                    outer.current,
                    { scale: 2, opacity: 0 },
                    { scale: 2, opacity: 0, duration: 0, force3D: true },
                    0
                );
                tlOff.fromTo(
                    inner.current,
                    { scale: 0, opacity: 0 },
                    { scale: 1, opacity: 1, duration: 0.5, force3D: true },
                    0
                );
                tlOff.fromTo(
                    fill.current,
                    { scale: 1, opacity: 1 },
                    { scale: 2, opacity: 0, duration: 0.5, force3D: true },
                    0
                );

                setTimelineOn(tlOn);
                setTimelineOff(tlOff);
            }
        }, [outer.current, inner.current, fill.current, selected]);

        useEffect(() => {
            if (timelineOn && timelineOff) {
                if (selected) {
                    // animate in
                    timelineOn.play();
                } else {
                    // animate out
                    timelineOff.play();
                }
            }
        }, [selected, timelineOn, timelineOff]);

        return (
            <Container x={x} y={y} hidden={hidden} {...props}>
                <OuterRing viewBox="0 0 28 28" ref={outer}>
                    <circle cx="14" cy="14" r={13} stroke="white" strokeWidth={1} fill="none" />
                </OuterRing>
                <InnerRing viewBox="0 0 28 28" ref={inner}>
                    <circle cx="14" cy="14" r={13} stroke="white" strokeWidth={1} fill="none" />
                </InnerRing>
                <Fill viewBox="0 0 28 28" ref={fill}>
                    <circle cx="14" cy="14" r={13} fill="white" />
                </Fill>
                <Dot viewBox="0 0 28 28">
                    <circle cx="14" cy="14" r={7} fill={inactive ? '#999' : '#DC1111'} />
                </Dot>
                {link && <LinkContainer>{link}</LinkContainer>}
                {children}
            </Container>
        );
    }
);

const cssPositioning = css<{ x?: number; y?: number }>`
    position: absolute;
    left: ${({ x }) => x}%;
    top: ${({ y }) => y}%;
    transform: translateX(-50%) translateY(-50%);
`;

const Container = styled.div<{ x?: number; y?: number; onClick?: any; hidden?: boolean }>`
    position: relative;
    ${({ x, y }) => (typeof x !== 'undefined' && typeof y !== 'undefined' ? cssPositioning : null)}
    width: ${cssCalcBetweenViewport(20, 28)};
    height: ${cssCalcBetweenViewport(20, 28)};
    border-radius: 100%;
    padding: 0rem;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    opacity: ${({ hidden }) => (hidden ? 0 : 1)};

    & > svg {
        ${cover()}
        will-change: transform;
    }
`;

const OuterRing = styled.svg`
    opacity: 0;
    pointer-events: none;
`;

const InnerRing = styled.svg`
    opacity: 0;
    pointer-events: none;
`;

const Fill = styled.svg`
    opacity: 0;
    pointer-events: none;
`;

const Dot = styled.svg`
    pointer-events: none;
`;

const LinkContainer = styled.span`
    & > a {
        display: block;
        ${cover()}
        ${hideText()}
    }
`;

export default BlipDefault;
