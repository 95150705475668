import { activity } from "../components/ProgramAndActivities";

/**
    Todo: fix this so the types are sorted by the order in the cms, not hardcoded like this.
 */
export default (array: activity[], sortingArr: string[]) => {
    const workArray = [...array];
    return workArray.sort(function(a, b){  
        return sortingArr.indexOf(a.slug) - sortingArr.indexOf(b.slug);
    });
}