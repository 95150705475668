import { useDayNavigation } from './useDayNavigation';
import { Craft_EventsEvent } from '../graphql';
import eventNowLive from './eventNowLive';

const eventMatchFilter = (event: Craft_EventsEvent, filters: Record<string, boolean>) =>
    event.activityTypes_FieldData?.selected?.find((at) => at?.value && filters[at?.value]) ||
    (event.activityTypes_FieldData?.selected?.length === 0 && Object.values(filters).indexOf(false) === -1);

const liveNowOrLiveFilterOff = (event: Craft_EventsEvent, day: Date, liveOnly: boolean) =>
    liveOnly ? eventNowLive(event, day) : true;

export default (
    data: ReturnType<typeof useDayNavigation>['data'],
    filters: Record<string, boolean>,
    liveOnly: boolean,
    dayNotToday: boolean
) =>
    data?.events.filter(
        (event, i) =>
            eventMatchFilter(event, filters) && (dayNotToday || liveNowOrLiveFilterOff(event, data.date, liveOnly))
    ) || [];
