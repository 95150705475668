import React, { FC, ImgHTMLAttributes } from "react";
import ImageLoading from "react-image-loading";
import styled from "styled-components";

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    ratio: number;
}

const Image: FC<ImageProps> = ({ src, srcSet, ratio, ...props }) => (
    <Container ratio={ratio}>
        <svg viewBox={`0 0 ${100 * ratio} 100`} />
        <ImageLoading>
            {(ref, status) =>
                status === "error" ? (
                    <span>error</span>
                ) : (
                    <Img
                        src={src}
                        srcSet={srcSet}
                        isLoading={status === "loading"}
                        {...props}
                        ref={ref}
                    />
                )
            }
        </ImageLoading>
    </Container>
);

const Container = styled.figure<{ ratio: number }>`
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;

    & > svg {
        width: 100%;
    }
`;

const Img = styled.img<{ isLoading: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
    transform: scale(${({ isLoading }) => (isLoading ? 1.05 : 1)});
`;

export default Image;
