import {
    Craft_EventsEvent,
    Craft_EventsHost,
    Craft_EventsLink,
    Craft_EventsQuote,
    Craft_Onlinefestival,
    OnlinefestivalQuery,
} from '../graphql';
import { useEffect, useState } from 'react';
import { eachDay, endOfDay, isWithinRange, startOfDay } from 'date-fns';
import getFestivalPeriod from '../utils/getFestivalPeriod';

interface ParsedData {
    quote: Craft_EventsQuote | null;
    host: Craft_EventsHost | null;
    events: Craft_EventsEvent[];
    links: Craft_EventsLink[];
    __typename?: 'Craft_Onlinefestival' | undefined;
    title: string;
    date: Date;
    introMedia?: string | null;
}

const parseData = (data: OnlinefestivalQuery): Array<ParsedData | null> => {
    const entries = (data.craft.entries ?? []) as Craft_Onlinefestival[];
    const { startDate, endDate } = getFestivalPeriod(entries);

    // now get each day between start and end
    const days = eachDay(new Date(startDate * 1000), new Date(endDate * 1000));
    return days.map((date) => {
        let entry =
            // first try to find current date simply by checking if the startDate matches with current day
            entries.find((entry) => +startOfDay(new Date(entry.playDate * 1000)) === +startOfDay(date)) ??
            // otherwise, fall back to checking if this date falls within a period
            entries.find((entry) =>
                isWithinRange(
                    date,
                    startOfDay(new Date(entry.playDate * 1000)),
                    endOfDay(entry.endDate ? new Date(entry.endDate * 1000) : new Date(entry.playDate * 1000))
                )
            );

        if (entry) {
            const day = {
                ...(({ events, ...rest }) => rest)(entry),
                quote: null as Craft_EventsQuote | null,
                host: null as Craft_EventsHost | null,
                events: [] as Craft_EventsEvent[],
                links: [] as Craft_EventsLink[],
                date,
            };
            entry?.events?.map((e) => {
                switch (e?.__typename) {
                    case 'Craft_EventsQuote':
                        day['quote'] = e;
                        break;
                    case 'Craft_EventsHost':
                        day['host'] = e;
                        break;
                    case 'Craft_EventsEvent':
                        day['events'] = [...day['events'], e];
                        break;
                    case 'Craft_EventsLink':
                        day['links'] = [...day['links'], e];
                        break;
                }
            });
            return day;
        }

        // shouldn't possibly happen, but hey
        return null;
    });
};

const useParsedOnlinefestivalQuery = (data: OnlinefestivalQuery) => {
    const [parsedData, setParsedData] = useState<ParsedData[]>([]);
    const [dataLoading, setDataLoading] = useState(true);

    // parsing in useEffect to avoid gatsby parsing the data on the server
    useEffect(() => {
        setParsedData(parseData(data).filter((entry): entry is ParsedData => !!entry));
        setDataLoading(false);
    }, []);

    return { parsedData, dataLoading };
};

export default useParsedOnlinefestivalQuery;
