import React, { FC } from "react";
import BlipDefault from "./BlipDefault";
import BlipIcon from "./BlipIcon";
import BlipLive from "./BlipLive";

type variant = "live" | "icon" | "default" | "inactive";

export interface BlipProps extends React.HTMLAttributes<HTMLElement> {
    variant?: variant;
    x?: number;
    y?: number;
    selected?: boolean;
    icon?: React.ReactNode;
    link?: React.ReactNode;
    hidden?: boolean;
    inactive?: boolean;
}

const Blip: FC<BlipProps> = React.memo(({ variant = "default", icon, ...props }) => {
    return variant === "live" ? (
        <BlipLive {...props} />
    ) : variant === "icon" && icon ? (
        <BlipIcon icon={icon} {...props} />
    ) : (
        <BlipDefault {...props} />
    );
});

export default Blip;
