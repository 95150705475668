import React from 'react';
import styled from 'styled-components';

import cssCalcBetweenViewport from '../utils/cssCalcBetweenViewport';
import Button from './ds/action/Button';
import ButtonText from './ds/action/ButtonText';
import BlockModal, { BlockModalProps } from './ds/block/BlockModal';
import { H1, Body } from './ds/primitive/typography';

const AuthBlockModal: React.FC<BlockModalProps> = ({ ...props }) => (
    <StyledBlockModal canClose={false} {...props}>
        <Container>
            <Title variant="large">Koop nu je ticket om voorstellingen mee te maken</Title>
            <Body>Als je al een ticket hebt gekocht, ga je verder door in te loggen.</Body>
            <Buttons>
                <Button as="a" href={process.env.GATSBY_TICKET_URL} label="Koop ticket" type="fill" /> <span>Of</span>{' '}
                <ButtonText as="a" label="Inloggen" href={process.env.GATSBY_LOGIN_URL} />
            </Buttons>
        </Container>
    </StyledBlockModal>
);

const StyledBlockModal = styled(BlockModal)`
    padding: ${cssCalcBetweenViewport(20, 32)};
    padding-right: ${cssCalcBetweenViewport(44, 56)};
    width: 100%;
    box-sizing: border-box;
`;

const Container = styled.div`
    & > * + * {
        margin-top: ${cssCalcBetweenViewport(16, 24)};
    }
`;

const Title = styled(H1)`
    &::after {
        content: '';
        display: block;
        height: 0;
        width: 40px;
        border-bottom: 3px solid #56cb28;
        margin-top: ${cssCalcBetweenViewport(8, 16)};
    }
`;

const Buttons = styled.div`
    & > * + * {
        margin-left: 1.6rem;
    }
`;

export default AuthBlockModal;
